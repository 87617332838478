"use client";

import { Button } from "@/components/ui/button";
import * as Sentry from "@sentry/nextjs";
import NextError from "next/error";
import { useEffect } from "react";
import Image from "next/image";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html>
      <body>
        {" "}
        <div className="grid h-screen w-screen place-items-center">
          <div className="flex items-center gap-2">
            <Image
              src="/dealpage.png"
              alt="Paige"
              width={25}
              height={25}
              className="rounded-md"
            />
            <p className="font-semibold">Something went wrong!</p>
            <Button onClick={() => reset()}>Reload</Button>
          </div>
        </div>
      </body>
    </html>
  );
}
